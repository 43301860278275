/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import CookieConsent from "react-cookie-consent"
import { Link } from "gatsby"

const Layout = ({ children }) => {
  return (
    <>
      <main>{children}</main>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        cookieName="gatsby-gdpr-google-analytics"
        buttonStyle={{ background: "white" }}
        style={{ display: "flex", background: "#101115" }}
      >
        We use cookies and have recently updated our privacy and cookie policy.
        Continue browsing and using our service if you are happy with this and
        accept our{" "}
        <Link style={{ color: "var(--primary)" }} to="/privacy-policy">
          Privacy and Cookie Policy
        </Link>{" "}
        and our{" "}
        <Link style={{ color: "var(--primary)" }} to="/terms-conditions">
          Terms and Conditions
        </Link>
        .
      </CookieConsent>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
